import React from "react";
import IconCard from "../../common/IconCard";
import icon_management from "../../../assests/HPE/Landing-page/icon_managemnent.svg";
import icon_unmatched from "../../../assests/HPE/Landing-page/icon_unmatched.svg";
import icon_robust from "../../../assests/HPE/Landing-page/icon_robust.svg";
import icon_sustainability from "../../../assests/HPE/Landing-page/icon_sustainability.svg";
import icon_scalability from "../../../assests/HPE/Landing-page/icon_scalability.svg";

const features = [
  {
    image: icon_management,
    title: "Seamless Management",
    description:
      "Centralized control and automation for simplified IT operations.",
  },
  {
    image: icon_unmatched,
    title: "Unmatched Performance",
    description:
      "Optimized for any workload, delivering exceptional speed and efficiency.",
  },
  {
    image: icon_robust,
    title: "Robust Security",
    description:
      "Protect your data with built-in security features and a zero-trust approach.",
  },
  {
    image: icon_sustainability,
    title: "Sustainability",
    description:
      "Reduce energy consumption and carbon footprint with energy-efficient servers.",
  },
  {
    image: icon_scalability,
    title: "Scalability",
    description:
      "Easily scale your infrastructure to meet evolving business needs.",
  },
];

const Features = () => {
  return (
    <section className="bg-m-page4-bg md:bg-page4-bg bg-cover bg-center bg-no-repeat py-20 md:py-36">
      <div className="container inline-padding">
        <h1 className="text-white mb-6 md:mb-8 w-full md:w-3/4 xl:w-1/2">
          Unlock the Power of HPE ProLiant Gen11, Powered by Intel
        </h1>

        <div className="grid grid-cols-12 gap-4 lg:gap-6">
          {features.map(({ image, title, description }, index) => {
            return (
              <div
                className="col-span-12 md:col-span-6 lg:col-span-4"
                key={index}
              >
                <IconCard
                  image={image}
                  title={title}
                  description={description}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Features;
