const Footer = () => {
  return (
    <footer className="px-4 md:px-2 py-4 bg-blue-06 text-center">
      <p className="b3 text-white">
        Copyright © 2024 EnterInsights.com All rights reserved |{" "}
        <a href="/disclaimer">Disclaimer</a>
      </p>
    </footer>
  );
};

export default Footer;
