import React from "react";
import Legal from "../components/layout/Legal";

const PrivacyPolicy = () => {
  return (
    <Legal pageTitle="Privacy Policy">
      <div className="flex flex-col gap-16">
        <p className="b1 text-grey-05">
          EnterInsights.com respects your privacy and is committed to protecting
          your personal data. This Privacy Policy explains how we collect, use,
          and safeguard your information when you visit our website.
        </p>

        <div>
          <p className="b1 text-grey-08 mb-4">Information We Collect</p>
          <p className="b2 text-grey-05">
            we may collect and process the following information:
          </p>
          <br />
          <ol className="b2 text-grey-05 list-decimal ps-6">
            <li>
              Personal Information: This may include your name, email address,
              company name, and other information you voluntarily provide when
              engaging with campaigns or contacting us.
            </li>
            <li>
              Non-Personal Information: This includes data automatically
              collected when you visit our site, such as your IP address,
              browser type, device type, and browsing behavior.
            </li>
            <li>
              Cookies and Tracking Technologies: We use cookies and similar
              tracking technologies to improve your browsing experience and
              analyze website traffic. You may disable cookies through your
              browser settings, but doing so may affect your experience on our
              site.
            </li>
          </ol>
        </div>

        <div>
          <p className="b1 text-grey-08 mb-4">How We Use Your Information</p>
          <p className="b2 text-grey-05">
            EnterInsights.com uses the information we collect for the following
            purposes:
          </p>
          <br />
          <ol className="b2 text-grey-05 list-decimal ps-6">
            <li>To deliver marketing campaigns hosted on our site.</li>
            <li>
              To improve our website and services based on user behavior and
              feedback.
            </li>
            <li>
              To send you communications related to campaigns or promotional
              materials, with your consent.
            </li>
            <li>To comply with legal obligations.</li>
          </ol>
        </div>

        <div>
          <p className="b1 text-grey-08 mb-4">Data Sharing and Disclosure</p>
          <ol className="b2 text-grey-05 list-decimal ps-6">
            <li>
              Clients and Partners: If you interact with a specific campaign, we
              may share your information with the client running that campaign.
            </li>
            <li>
              Service Providers: We may share your information with third-party
              service providers who assist in operating our website, managing
              campaigns, or conducting business operations, provided that they
              adhere to privacy standards.
            </li>
            <li>
              Legal Compliance: We may disclose your personal information if
              required by law or to protect our rights, property, or safety.
            </li>
          </ol>
        </div>

        <div>
          <p className="b1 text-grey-08 mb-4">Data Security</p>
          <p className="b2 text-grey-05">
            We implement appropriate technical and organizational measures to
            protect your personal data. However, no method of transmission over
            the internet is entirely secure. While we strive to protect your
            data, we cannot guarantee its absolute security.
          </p>
        </div>

        <div>
          <p className="b1 text-grey-08 mb-4">Retention of Data</p>
          <p className="b2 text-grey-05">
            We will retain your personal data only for as long as necessary to
            fulfill the purposes outlined in this policy or as required by law.
          </p>
        </div>

        <div>
          <p className="b1 text-grey-08 mb-4">Your Rights</p>
          <p className="b2 text-grey-05">You have the right to:</p>
          <br />
          <ol className="b2 text-grey-05 list-decimal ps-6">
            <li>Access, update, or delete your personal data.</li>
            <li>Withdraw consent for data processing at any time.</li>
            <li>Request that we restrict or object to processing your data.</li>
          </ol>
        </div>

        <div>
          <p className="b1 text-grey-08 mb-4">Changes to This Privacy Policy</p>
          <p className="b2 text-grey-05">
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page, and the revised date will be updated at
            the top.
          </p>
        </div>
      </div>
    </Legal>
  );
};

export default PrivacyPolicy;
