import { ChevronLeft, ChevronRight } from "lucide-react";
import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ handlePageChange, pages, activePage = 0 }) => {
  // console.log("activePage: ", activePage);
  // console.log("pages: ", pages);
  return (
    <ReactPaginate
      breakLabel={<span className="text-grey-04">...</span>}
      nextLabel={
        <button className="pagination-nav-btn p-1 sm:p-2">
          <ChevronRight className="w-4 h-4 sm:w-6 sm:h-6" />
        </button>
      }
      previousLabel={
        <button className="pagination-nav-btn p-1 sm:p-2">
          <ChevronLeft className="w-4 h-4 sm:w-6 sm:h-6" />
        </button>
      }
      onPageChange={({ selected }) => handlePageChange(selected + 1)}
      pageRangeDisplayed={3}
      marginPagesDisplayed={1}
      pageCount={pages}
      forcePage={activePage}
      renderOnZeroPageCount={null}
      containerClassName="flex flex-wrap gap-2 items-center justify-center"
      pageLinkClassName="pagination-btn px-2.5 py-1 sm:px-4 sm:py-2"
      pageClassName="flex items-center"
      activeLinkClassName=""
      disabledLinkClassName="pointer-events-none"
    />
  );
};

export default Pagination;
