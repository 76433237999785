import Layout from "./Layout";
import React from "react";

const Legal = ({ pageTitle = "", children }) => {
  return (
    <Layout title={pageTitle}>
      <section className="bg-form-masthead-bg bg-no-repeat bg-[length:100%_40rem] pt-32 pb-10 md:pt-44 md:pb-36 mb-14 md:mb-24">
        <div className="inline-padding container">
          <h2 className="text-white">{pageTitle}</h2>
        </div>
      </section>
      <section className="inline-padding container mb-20 md:mb-28">
        {children}
      </section>
    </Layout>
  );
};

export default Legal;
