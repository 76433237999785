const CarouselCard = ({ upto, off, image, descrption }) => {
  return (
    <div className="cursor-grab select-none p-6 rounded-2xl bg-grey-01 bg-cards-bg bg-cover bg-no-repeat h-full">
      <div className="flex items-center justify-between  mb-8">
        <div className="text-grey-08">
          <p className="b4">{upto}</p>
          <h2>{off}</h2>
        </div>
        <img src={image} alt="flag" className="w-12 h-12" />
      </div>
      <p className="text-grey-06 b4">{descrption}</p>
    </div>
  );
};

export default CarouselCard;
