import { useEffect, useRef, useState } from "react";
import Button from "../components/common/Button";
import Input from "../components/common/Input";
import Layout from "../components/layout/Layout";
import http from "../config";
import { useNavigate } from "react-router-dom";
import { ArrowRight } from "lucide-react";
import Spinner from "../components/common/Spinner";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [isValidCredential, setIsValidCredential] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState(null);
  const emailRef = useRef();
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const modifiedData = {
      ...formData,
      email: formData.email.trim().toLowerCase(),
    };

    try {
      setIsSending(true);

      const response = await http.post("/login", modifiedData);

      if (response.data.success) {
        localStorage.setItem("token", response.data.accessToken);
        navigate("/leads");
      }
    } catch (error) {
      // console.log("error:", error);
      if (error.status === 401) {
        setError("Invalid email or password");
      }
    } finally {
      setIsSending(false);
    }
  };

  const isValidEmail = (email) => {
    return emailRegex.test(email);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const { email, password } = formData;
    if (name === "email") {
      setIsValidCredential(isValidEmail(value.trim()) && password);
    } else {
      setIsValidCredential(isValidEmail(email.trim()) && value);
    }

    setError(null);
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  useEffect(() => {
    if (token) {
      navigate("/leads");
    }

    emailRef.current?.focus();
  }, []);

  return (
    <Layout title="Login">
      <div className="min-h-screen flex justify-center items-center px-4">
        <form className="w-full max-w-[500px]" onSubmit={handleFormSubmit}>
          <h2 className="mb-8 text-center">Login</h2>
          {error && <p className="b3 text-red-500 mb-3">{error}</p>}
          <Input
            name="email"
            placeholder="Enter your email"
            styles="mb-2"
            value={formData.email}
            onChange={handleInputChange}
            ref={emailRef}
          />
          <Input
            type="password"
            name="password"
            placeholder="Enter your password"
            styles="mb-6"
            value={formData.password}
            onChange={handleInputChange}
          />
          <Button
            text={isSending ? "Login" : "Login"}
            disabled={!isValidCredential || isSending}
            Icon={isSending ? Spinner : ArrowRight}
          />
        </form>
      </div>
    </Layout>
  );
};

export default Login;
