import { useEffect, useRef, useState } from "react";
import Button from "../../common/Button";
import Input from "../../common/Input";
import Popup from "../../common/Popup";
import { Link, useLocation } from "react-router-dom";
import Select from "../../common/Select";
import axios from "../../../config";
import { object, string } from "yup";
import TextError from "../../common/TextError";

// const phoneRegExp = /^\s*[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/;
const phoneRegexByCountry = {
  Australia: /^\d{4} ?\d{3} ?\d{3}$/,
  "Hong Kong": /^\d{4} ?\d{4}$/,
  Singapore: /^\d{4} ?\d{4}$/,
  "United States": /^\(\d{3}\) \d{3}-\d{4}$/,
};
const phoneDigitByCountry = {
  Australia: 10,
  "Hong Kong": 8,
  Singapore: 8,
  "United States": 10,
};
const formatByCountry = {
  Australia: {
    digitCount: 10,
    pattern: /(\d{4})(\d{3})(\d{3})/,
    replacement: "$1 $2 $3",
  },
  "Hong Kong": {
    digitCount: 8,
    pattern: /(\d{4})(\d{4})/,
    replacement: "$1 $2",
  },
  Singapore: {
    digitCount: 8,
    pattern: /(\d{4})(\d{4})/,
    replacement: "$1 $2",
  },
  "United States": {
    digitCount: 10,
    pattern: /(\d{3})(\d{3})(\d{4})/,
    replacement: "($1) $2-$3",
  },
};
const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const validationSchema = object({
  first_name: string().trim().required("Please enter your first name"),
  email: string()
    .trim()
    .matches(emailRegExp, "Please enter a valid email")
    .required("Please enter an email"),
  company: string().trim().required("Please select your country"),
  country: string().required("Please enter your country"),
  phone: string()
    .trim()
    .when("country", (country, schema) => {
      const phoneRegex = phoneRegexByCountry[country];
      return phoneRegex
        ? schema.matches(phoneRegex, `Phone number is not valid for ${country}`)
        : schema.required("Phone number is required");
    })
    .required("Phone number is required"),
  address: string().trim().required("Please enter your address"),
  designation: string().trim().required("Please enter your designation"),
  interested_in: string().required("Please select an answer"),
  device_purchases: string().required("Please select an answer"),
  budget: string().required("Please select an answer"),
  time_frame: string().required("Please select an answer"),
});

const countryCodeMap = {
  Australia: "+61",
  "Hong Kong": "+852",
  Singapore: "+65",
  "United States": "+1",
};

const DEFAULT_STATE_SELECTED = "United States";

const Form = () => {
  const location = useLocation();
  // console.log("location: ", location);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company: "",
    phone: "",
    address: "",
    country: DEFAULT_STATE_SELECTED,
    designation: "",
    interested_in: "",
    device_purchases: "",
    budget: "",
    time_frame: "",
  });

  const [errors, setErrors] = useState({});
  const [currentCountryCode, setCurrentCountryCode] = useState(
    countryCodeMap[DEFAULT_STATE_SELECTED]
  );
  const [showPopUP, setShowPopUp] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [termsCheck, setTermsCheck] = useState(false);
  const [termsCheckError, setTermsCheckError] = useState(false);
  const inputRefs = useRef([]);

  function handleInputChange(e) {
    const { name, value } = e.target;

    if (name === "country") {
      setCurrentCountryCode(!value ? "--" : countryCodeMap[value]);
    }

    if (name === "phone") {
      const formattedValue = formatPhoneNumber(value);
      setFormData((prevState) => ({
        ...prevState,
        [name]: formattedValue,
      }));
      return;
    }

    setFormData((prevData) => {
      return { ...prevData, [name]: value };
    });
  }

  function formatPhoneNumber(phoneNumber) {
    const formattedPhoneNumber = phoneNumber
      .replace(/\D/g, "")
      .slice(0, phoneDigitByCountry[formData.country]);
    const formattedValue = formattedPhoneNumber.replace(
      formatByCountry[formData.country].pattern,
      formatByCountry[formData.country].replacement
    );

    return formattedValue;
  }

  function handleTermsCheck(e) {
    setTermsCheckError(!e.target.checked);
    setTermsCheck(e.target.checked);
  }

  function clearFormData() {
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      company: "",
      phone: "",
      address: "",
      country: DEFAULT_STATE_SELECTED,
      designation: "",
      interested_in: "",
      device_purchases: "",
      budget: "",
      time_frame: "",
    });
    setTermsCheck(false);
    setTermsCheckError(false);
    setCurrentCountryCode(countryCodeMap[DEFAULT_STATE_SELECTED]);
  }

  function scrollToInvalidInput() {
    const invalidInputFieldNames = Object.keys(errors).filter(
      (key) => errors[key]
    );

    // console.log(invalidInputFieldNames);

    const invalidInputIndex = inputRefs.current.findIndex(
      (input) => input.name === invalidInputFieldNames[0]
    );

    if (invalidInputIndex !== -1) {
      inputRefs.current[invalidInputIndex].scrollIntoView({
        block: "center",
        behavior: "smooth",
      });

      setTimeout(() => {
        inputRefs.current[invalidInputIndex].focus();
      }, 500);
    }
  }

  function showErrors(errors) {
    const newErrors = {};
    errors.forEach((error) => {
      newErrors[error.path] = error.message;
    });
    setErrors(newErrors);
  }

  async function handleFormSubmit(e) {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});

      if (!termsCheck) {
        setTermsCheckError(true);
        return;
      }

      setIsButtonDisabled(true);
      const formatted_phone_number = `${currentCountryCode} ${formData.phone}`;

      const duplicatePhoneNumber = await axios.get(
        `/check-phone/${formatted_phone_number}`
      );

      // Check if phone number exists in the database
      window.dataLayer = window.dataLayer || [];

      if (duplicatePhoneNumber.data.exists) {
        window.dataLayer.push({
          event: "formSubmission",
          formType: "LeadForm",
          success: "duplicate",
        });
        // console.log(window.dataLayer);
      } else {
        window.dataLayer.push({
          event: "formSubmission",
          formType: "LeadForm",
          success: "unique",
        });
      }

      const response = await axios.post("/submit-form", {
        ...formData,
        phone: formatted_phone_number,
      });
      // console.log(response);
      setShowPopUp(true);
      clearFormData();
    } catch (error) {
      if (error.inner) {
        // console.log("validation errors: ", error.inner);
        showErrors(error.inner);
      } else {
        console.log("Axios error: ", error);
      }
    } finally {
      setIsButtonDisabled(false);
    }
  }

  useEffect(() => {
    scrollToInvalidInput();
  }, [errors]);

  useEffect(() => {
    setFormData((prevData) => {
      return { ...prevData, ...location.state };
    });
  }, []);

  return (
    <div className="w-full lg:w-[45%] px-4 md:px-10 py-8 bg-white rounded shadow-header mb-32">
      <form onSubmit={handleFormSubmit} className="flex flex-col gap-6">
        <div className="flex flex-col gap-6">
          <Input
            placeholder="Enter your first name"
            label="First name"
            name="first_name"
            value={formData.first_name}
            onChange={handleInputChange}
            error={errors.first_name}
            ref={(node) => (inputRefs.current[0] = node)}
          />
          <Input
            placeholder="Enter your last name"
            label="Last name"
            name="last_name"
            value={formData.last_name}
            onChange={handleInputChange}
            ref={(node) => (inputRefs.current[1] = node)}
          />
          <Input
            placeholder="Enter your work email"
            label="Work email"
            name="email"
            // type="email"
            value={formData.email}
            onChange={handleInputChange}
            error={errors.email}
            ref={(node) => (inputRefs.current[2] = node)}
          />
          <Input
            placeholder="Enter your company name"
            label="Company name"
            name="company"
            value={formData.company}
            onChange={handleInputChange}
            error={errors.company}
            ref={(node) => (inputRefs.current[3] = node)}
          />
          <Select
            label="Country"
            name="country"
            value={formData.country}
            onChange={handleInputChange}
            error={errors.country}
            ref={(node) => (inputRefs.current[4] = node)}
          >
            <option value="" disabled>
              Country
            </option>
            <option value="Australia">Australia</option>
            <option value="Hong Kong">Hong Kong</option>
            <option value="Singapore">Singapore</option>
            <option value="United States">United States</option>
          </Select>
          <Input
            placeholder="Enter your phone number"
            label="Phone number"
            name="phone"
            type="tel"
            prefixText={currentCountryCode}
            value={formData.phone}
            onChange={handleInputChange}
            error={errors.phone}
            ref={(node) => (inputRefs.current[5] = node)}
          />
          <Input
            placeholder="Enter your address"
            label="Address"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            error={errors.address}
            ref={(node) => (inputRefs.current[6] = node)}
          />
          {/* <Input
            placeholder="Enter your country"
            label="Country"
            name="country"
            value={formData.country}
            onChange={handleInputChange}
            error={errors.country}
            ref={(node) => (inputRefs.current[6] = node)}
          /> */}
          <Input
            placeholder="Enter your designation"
            label="Job title"
            name="designation"
            value={formData.designation}
            onChange={handleInputChange}
            error={errors.designation}
            ref={(node) => (inputRefs.current[7] = node)}
          />
        </div>

        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <p className="b3 text-grey-08">
              Would you be interested in a server solution which will help you
              to accelerate AI outcomes with a trusted edge to cloud security
              architecture?
            </p>
            <Select
              label="Answer"
              name="interested_in"
              value={formData.interested_in}
              onChange={handleInputChange}
              error={errors.interested_in}
              ref={(node) => (inputRefs.current[8] = node)}
            >
              <option value="">Select your answer</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
          </div>
          <div className="flex flex-col gap-4">
            <p className="b3 text-grey-08">
              Are you a decision maker or influencer when it comes to Device
              purchases and Technology investment at your organization?
            </p>
            <Select
              label="Answer"
              name="device_purchases"
              value={formData.device_purchases}
              onChange={handleInputChange}
              error={errors.device_purchases}
              ref={(node) => (inputRefs.current[9] = node)}
            >
              <option value="">Select your answer</option>
              <option value="Decision Maker">Decision Maker</option>
              <option value="Influencer">Influencer</option>
              <option value="Technical Evaluator">Technical Evaluator</option>
            </Select>
          </div>
          <div className="flex flex-col gap-4">
            <p className="b3 text-grey-08">
              Do you have a budget in mind for the above?
            </p>
            <Select
              label="Answer"
              name="budget"
              value={formData.budget}
              onChange={handleInputChange}
              error={errors.budget}
              ref={(node) => (inputRefs.current[10] = node)}
            >
              <option value="">Select your answer</option>
              <option value="Yes">Yes</option>
              <option value="Not Yet Firmed up">Not Yet Firmed up</option>
              <option value="In Discussion">In Discussion</option>
              <option value="We do not have any budget">
                We do not have any budget{" "}
              </option>
            </Select>
          </div>
          <div className="flex flex-col gap-4">
            <p className="b3 text-grey-08">
              In what timeframe would you like to invest in such a solution?
            </p>
            <Select
              label="Answer"
              name="time_frame"
              value={formData.time_frame}
              onChange={handleInputChange}
              error={errors.time_frame}
              ref={(node) => (inputRefs.current[11] = node)}
            >
              <option value="">Select your answer</option>
              <option value="3-6 Months">3-6 Months</option>
              <option value="6-9 Months">6-9 Months</option>
              <option value="9-12 Months">9-12 Months</option>
              <option value="12+ Months">12+ Months</option>
            </Select>
          </div>
        </div>
        <div>
          <div className="flex gap-4 items-baseline">
            <input
              type="checkbox"
              id="terms"
              name="terms"
              onChange={handleTermsCheck}
              checked={termsCheck}
            />
            <label htmlFor="terms" className="b3 text-grey-05">
              I have read and agree to{" "}
              <a className="text-blue-06" href="/terms">
                HPE Terms of use
              </a>{" "}
              and understand that my personal information will be processed in
              accordance with{" "}
              <a className="text-blue-06" href="/privacy-policy">
                HPE Privacy policy
              </a>
              .
            </label>
          </div>
          {termsCheckError && (
            <TextError error="Please mark the above checkbox" />
          )}
        </div>
        <div className="b3 text-grey-05">
          By supplying my contact information, I authorise HPE to contact me
          with personalized communication about HPE products and services. See
          our Privacy Policy link (above) for more details or to opt-out at any
          time.
        </div>
        <Button
          text={!isButtonDisabled ? "Submit" : "Submitting..."}
          disabled={isButtonDisabled}
        />
      </form>

      {showPopUP && (
        <Popup styles="fixed top-0 left-0" SetShowPopUp={setShowPopUp} />
      )}
    </div>
  );
};
export default Form;
