import hewlett from "../../assests/HPE/Landing-page/Group.svg";

import frame from "../../assests/HPE/Landing-page/Frame.svg";

const Header = () => {
  return (
    <header className="px-4 lg:px-6 absolute top-4 left-0 w-full">
      <div className="rounded-lg bg-white">
        <div className="py-4 flex items-center justify-center">
          <a href="/" className="flex items-center justify-center gap-x-5">
            <div>
              <img src={hewlett} alt="hewlett" />
            </div>
            <div className="h-[40px]  border-r-4 border-black"></div>
            <div>
              <img src={frame} alt="frame" />
            </div>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
