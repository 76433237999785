import { useNavigate } from "react-router-dom";
import product from "../../../assests/HPE/Landing-page/s_product.webp";
import Button from "../../common/Button";
import ScrollText from "../../ScrollText/ScrollText";
import { useRef } from "react";

// import Page1Content from "./Page1Content";

const Masthead = () => {
  const navigate = useNavigate();
  const firstNameRef = useRef(null);

  const navigateToForm = (e) => {
    e.preventDefault();
    const params = new URLSearchParams(window.location.search);
    const utm_source = params.get("utm_source") || "";
    const utm_medium = params.get("utm_medium") || "";
    const utm_campaign = params.get("utm_campaign") || "";

    navigate("/form", {
      state: {
        first_name: firstNameRef.current?.value.trim(),
        utm_source,
        utm_medium,
        utm_campaign,
      },
    });
  };
  return (
    <>
      <section className="w-full min-h-screen bg-m-masthead-bg md:bg-masthead-bg bg-cover bg-center bg-no-repeat pt-36 md:pt-56 pb-14">
        <div className="container inline-padding">
          <div className="flex flex-col lg:flex-row items-start justify-between mx-auto mb-16">
            <div className="w-full lg:w-[50%] mb-4 lg:mb-0">
              <h1 className="text-white mb-6">
                HPE ProLiant Gen11: Powered by Intel, Engineered for Your Hybrid
                World
              </h1>
              <p className="text-white b1">
                Seamlessly manage your distributed compute infrastructure,
                ensure robust security, and optimize performance for any
                workload.
              </p>
            </div>
            <div className="w-full lg:w-[30%] bg-blue-10 py-8 lg:py-10 px-6 rounded-lg">
              <form
                onSubmit={navigateToForm}
                className="flex flex-col gap-y-12"
              >
                <div>
                  <h3 className="text-white mb-4">Get Started now!</h3>
                  <div className="flex flex-col gap-1">
                    <label className="b4 text-blue-04">First name</label>
                    <input
                      ref={firstNameRef}
                      placeholder="Enter your name"
                      className="px-4 py-3 input-field text-grey-04 bg-grey-01 outline-none border border-transparent rounded hover:border-[1.5px] hover:border-blue-06 hover:bg-white"
                    />
                  </div>
                </div>

                <Button text="Continue" />
              </form>
            </div>
          </div>
          <img src={product} alt="product" className="w-full" />
        </div>
      </section>
      <ScrollText />
    </>
  );
};

export default Masthead;
