import { ChevronDown, Info } from "lucide-react";
import { Children, forwardRef } from "react";
import TextError from "./TextError";

const Select = forwardRef(
  ({ children, label, style = "", error, ...rest }, ref) => {
    return (
      <div>
        <div className="select-wrapper">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <label className="text-blue-04">{label}</label>
              <div className="relative">
                <select
                  ref={ref}
                  className=" w-full px-4 py-3 input-field text-grey-04 bg-grey-01 border-r-[1.5px] border-grey-01 outline-none rounded border border-transparent hover:border-[1.5px] hover:border-blue-06 hover:bg-white focus:border-blue-06 focus:bg-white focus-within:border-blue-6 focus-within:bg-white"
                  {...rest}
                >
                  {children}
                </select>
                <ChevronDown className="absolute top-1/2 translate-y-[-50%] right-5 text-grey-04" />
              </div>
              {error && <TextError error={error} />}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default Select;
