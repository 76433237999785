import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Layout from "../components/layout/Layout";
import Form from "../components/sections/formpage/Form";

const Formpage = () => {
  return (
    <Layout title="Form">
      <div className="w-full h-full bg-form-m-masthead-bg sm:bg-form-masthead-bg bg-no-repeat bg-[length:100%_40rem] pt-36 md:pt-56">
        <section className="container mx-auto">
          <div className="flex-col justify-between items-start lg:flex lg:flex-row md:px-4 px-0 md:mx-12 mx-0">
            <div className="lg:w-[45%] w-full  flex flex-col gap-2 mb-11 lg:mb-0">
              <div className="h2 text-white">
                Transform Your IT Today & Secure Exclusive Discounts
              </div>
              <p className="b2 text-grey-01">
                Experience seamless management, robust security, and optimized
                performance.
              </p>
              <p className="b2 text-grey-01">
                Request a free consultation now and unlock limited-time savings.
                We'll be in touch promptly to discuss your needs.
              </p>
            </div>
            <Form />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Formpage;
