import cloudIcon from "../../../assests/HPE/Landing-page/icon_cloud.svg";
import securityIcon from "../../../assests/HPE/Landing-page/icon_security.svg";
import performanceIcon from "../../../assests/HPE/Landing-page/icon_performance.svg";
import efficientIcon from "../../../assests/HPE/Landing-page/icon_efficient.svg";
import IconCard from "../../common/IconCard";

const data = [
  {
    image: cloudIcon,
    title: "Intuitive cloud operating experience",
    description:
      "Seamlessly monitor, manage and gain global visibility of your distributed compute environment.",
  },
  {
    image: securityIcon,
    title: "Trusted security by design",
    description:
      "Protect your compute environment, from factory to silicon to software, with a zero-trust security approach.",
  },
  {
    image: performanceIcon,
    title: "Optimized performance for any workload",
    description:
      "Drive workload efficiencies and benefit from world-record performance for your most demanding applications.",
  },
  {
    image: efficientIcon,
    title: "Efficient IT for sustainable initiatives",
    description:
      "Reduce your footprint and energy consumption with energy-efficient servers and resource optimization tools.",
  },
];

const Empower = () => {
  return (
    <section className="bg-m-page2-bg md:bg-page2-bg bg-cover bg-center bg-no-repeat py-20 md:py-36">
      <div className="container inline-padding">
        <h1 className="text-grey-08 mb-6 md:mb-8 w-full md:w-3/4 xl:w-1/2">
          Empower Your Business with Intelligent Compute
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-6">
          {data.map(({ image, title, description }, index) => {
            return (
              <div className="col-span-1" key={index}>
                <IconCard
                  image={image}
                  title={title}
                  description={description}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Empower;
