import React from "react";
import dashboard_image from "../../../assests/HPE/Landing-page/s_greenlake-dashboard.webp";

const Solution = () => {
  return (
    <section className="py-20 md:py-36">
      <div className="container inline-padding flex flex-col lg:flex-row gap-6">
        <div className="w-full lg:w-3/5 xl:w-5/12">
          <h3 className="text-grey-08 mb-2">
            A Complete Solution for Your Hybrid IT
          </h3>
          <p className="b3 text-grey-05">
            HPE ProLiant servers seamlessly integrate with a broad ecosystem of
            partners and solutions, providing flexibility and choice. Experience
            the intuitive cloud operating experience of HPE GreenLake for
            Compute Ops Management, simplifying IT operations and empowering
            innovation.
          </p>
        </div>
        <div className="w-full">
          <img src={dashboard_image} alt="dashboard-image" />
        </div>
      </div>
    </section>
  );
};

export default Solution;
