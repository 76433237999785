import React from "react";
import Legal from "../components/layout/Legal";

const TermsAndCondition = () => {
  return (
    <Legal pageTitle="Terms and Conditions">
      <div className="flex flex-col gap-16">
        <p className="b1 text-grey-05">
          Welcome to EnterInsights.com. By accessing and using our website, you
          agree to comply with and be bound by the following terms and
          conditions. Please read these carefully before using the site.
        </p>

        <div>
          <p className="b1 text-grey-08 mb-4">Acceptance of Terms</p>
          <p className="b2 text-grey-05">
            By using EnterInsights.com, you agree to these Terms and Conditions.
            If you do not agree with any part of these terms, you are prohibited
            from using or accessing this site.
          </p>
        </div>

        <div>
          <p className="b1 text-grey-08 mb-4">Services</p>
          <p className="b2 text-grey-05">
            EnterInsights.com provides a platform for hosting marketing
            campaigns on behalf of our B2B, enterprise, and technology clients.
            The campaigns featured on our site are for promotional purposes only
            and are subject to the terms set forth by the respective client.
          </p>
        </div>

        <div>
          <p className="b1 text-grey-08 mb-4">Intellectual Property</p>
          <p className="b2 text-grey-05">
            All content on EnterInsights.com, including but not limited to text,
            graphics, logos, and software, is the property of EnterInsights.com
            or our clients and is protected by intellectual property laws.
            Unauthorized use of the content may violate copyright, trademark,
            and other laws.
          </p>
        </div>

        <div>
          <p className="b1 text-grey-08 mb-4">User Responsbilities</p>
          <p className="b2 text-grey-05">
            By using this site, you agree not to:
          </p>
          <br />
          <ol className="b2 text-grey-05 list-decimal ps-6">
            <li>Use the site for any unlawful purpose.</li>
            <li>
              Attempt to gain unauthorized access to any part of the site.
            </li>
            <li>Interfere with the proper functioning of the site.</li>
          </ol>
        </div>

        <div>
          <p className="b1 text-grey-08 mb-4">Links to Third-Party Websites</p>
          <p className="b2 text-grey-05">
            EnterInsights.com may contain links to third-party websites. These
            links are provided for convenience only, and we do not endorse or
            assume responsibility for the content of any third-party site.
          </p>
        </div>

        <div>
          <p className="b1 text-grey-08 mb-4">Limitation of Liability</p>
          <p className="b2 text-grey-05">
            EnterInsights.com is not liable for any damages, including but not
            limited to direct, indirect, incidental, or consequential damages,
            arising out of your use or inability to use this site or any
            campaigns featured on it.
          </p>
        </div>

        <div>
          <p className="b1 text-grey-08 mb-4">Change to Terms</p>
          <p className="b2 text-grey-05">
            We reserve the right to modify these Terms and Conditions at any
            time. Continued use of the site after any changes are made
            constitutes acceptance of those changes.
          </p>
        </div>
      </div>
    </Legal>
  );
};

export default TermsAndCondition;
