import { Info } from "lucide-react";
import React from "react";

const TextError = ({ error }) => {
  return (
    <div className="flex items-center gap-1">
      <Info size={12} className="text-red-500" />
      <p className="caption text-red-500">{error}</p>
    </div>
  );
};

export default TextError;
