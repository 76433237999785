import CarouselCard from "../components/common/CarouselCard";
import australia from "../assests/HPE/Landing-page/flag-australia.svg";
import honkkong from "../assests/HPE/Landing-page/flag-honkkong.svg";
import singapore from "../assests/HPE/Landing-page/flag-singapore.svg";
import taiwan from "../assests/HPE/Landing-page/flag-taiwan.svg";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CarouselPage = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="container mx-auto inline-padding">
      <h1 className="mb-6 lg:mb-8">Unlock Exclusive Offers</h1>
      <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={true}
        showDots={true}
        removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
        className="min-h-[15rem] md:min-h-[18rem] container mb-8 md:mb-36"
      >
        <div className="mr-6 h-full">
          <CarouselCard
            upto={"Get up to"}
            off={"87% off"}
            image={australia}
            descrption={
              "Avail exclusive discounts of 75% on small, 86% on medium and 87% on large server bundles."
            }
          />
        </div>
        <div className="mr-6 h-full">
          <CarouselCard
            upto={"Get up to"}
            off={"83% off"}
            image={honkkong}
            descrption={
              "Get a free Microsoft Windows Server 2022 16-core Datacenter Reseller Option Kit. English/Korean/Japanese SW"
            }
          />
        </div>
        <div className="mr-6 h-full">
          <CarouselCard
            upto={"Get an additional"}
            off={"10% off"}
            image={singapore}
            descrption={
              "Get in touch to know about current discounts and avail an extra 10% on top of it."
            }
          />
        </div>
        <div className="mr-6 h-full">
          {" "}
          <CarouselCard
            upto={"Get an additional"}
            off={"10% off"}
            image={taiwan}
            descrption={
              "Get in touch to know about current discounts and avail an extra 10% on top of it."
            }
          />
        </div>
      </Carousel>
    </section>
  );
};

export default CarouselPage;
