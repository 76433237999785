import React from "react";
import "./style/main.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Homepage } from "./pages/Homepage";
import Formpage from "./pages/Formpage";
import LeadsPage from "./pages/LeadsPage";
import Login from "./pages/Login";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndCondition from "./pages/TermsAndCondition";
import Disclaimer from "./pages/Disclaimer";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("token");

  return token ? <Component {...rest} /> : <Navigate to="/auth" />;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/form" element={<Formpage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsAndCondition />} />
        <Route path="/disclaimer" element={<Disclaimer />} />

        <Route path="/auth" element={<Login />} />
        <Route path="/leads" element={<PrivateRoute component={LeadsPage} />} />
      </Routes>
    </Router>
  );
};

export default App;
