import { CircleCheck, X } from "lucide-react";
import Button from "./Button";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const Popup = ({ styles = "", SetShowPopUp }) => {
  const navigate = useNavigate();
  const [showPop, SetShowPop] = useState(true);
  const popUpRef = useRef(null);

  function showPopUp() {
    SetShowPop(false);
    SetShowPopUp(false);
  }

  function outSideClick(e) {
    if (popUpRef.current && !popUpRef.current.contains(e.target)) {
      SetShowPop(false);
      SetShowPopUp(false);
    }
  }

  return (
    <div
      className={`w-screen h-screen flex items-center justify-center ${styles}`}
      onClick={outSideClick}
    >
      {showPop && (
        <div className="w-full flex items-center justify-center">
          <div className="absolute inset-0 bg-black bg-opacity-60 backdrop-blur-sm"></div>

          <div
            className={`bg-blue-10 text-white w-[90%] lg:w-[40%] rounded-2xl relative z-10`}
            ref={popUpRef}
          >
            <div className="absolute top-7 right-8">
              <X onClick={showPopUp} className="cursor-pointer" />
            </div>
            <div className="flex flex-col gap-8 px-4 pt-6 pb-8 lg:pt-16 lg:px-16 lg:pb-20">
              <div>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col gap-4">
                    <CircleCheck className="w-[64px] h-[64px]" />
                    <div className="h2">Thank you</div>
                  </div>
                  <div className="b2">
                    Your request has been successfully received, and we're
                    excited to help you find the perfect servers and solutions
                    for your business.
                  </div>
                </div>
              </div>

              <div className="w-fit">
                <Button text="Explore products" onClick={() => navigate("/")} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>

    //       <div className="relative min-h-screen bg-gray-900 flex items-center justify-center">
    //   <div className="absolute inset-0 bg-gray-800 bg-opacity-50 backdrop-blur-sm"></div>

    //   <div
    //     className="bg-blue-10 text-white w-[90%] lg:w-[40%] rounded-2xl relative z-10"
    //     ref={popUpRef}
    //   >
    //     <div className="absolute top-7 right-8">
    //       <X onClick={showPopUp} className="cursor-pointer" />
    //     </div>
    //     <div className="flex flex-col gap-8 px-4 pt-6 pb-8 lg:pt-16 lg:px-16 lg:pb-20">
    //       <div>
    //         <div className="flex flex-col gap-2">
    //           <div className="flex flex-col gap-4">
    //             <CircleCheck className="w-[64px] h-[64px]" />
    //             <div className="h2">Thank you</div>
    //           </div>
    //           <div className="b2">
    //             Leo aliquet feugiat arcu dolor gravida purus elit vitae. Quis malesuada neque dignissim integer.
    //           </div>
    //         </div>
    //       </div>

    //       <div className="w-fit">
    //         <Button text="Explore products" />
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Popup;
