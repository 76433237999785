import { ArrowRight } from "lucide-react";

const Button = ({ text, styles = "", Icon = ArrowRight, ...rest }) => {
  return (
    <button
      className={`button bg-blue-06 rounded py-3 px-10 flex gap-1 items-center justify-center text-white button hover:bg-blue-04 w-full active:bg-blue-07 disabled:bg-grey-02 disabled:text-grey-01 ${styles}`}
      {...rest}
    >
      {text} <Icon />
    </button>
  );
};

export default Button;
