const ScrollText = () => {
  return (
    <div className="text-white py-6 md:py-8 w-full bg-blue-06 scrolling-container mb-20 md:mb-36">
      <h3 className="scrolling-text">
        Get Exclusive Limited Time Period Discounts - Unlock Now!
      </h3>
      <h3 className="scrolling-text">
        Get Exclusive Limited Time Period Discounts - Unlock Now!
      </h3>
      <h3 className="scrolling-text">
        Get Exclusive Limited Time Period Discounts - Unlock Now!
      </h3>
      <h3 className="scrolling-text">
        Get Exclusive Limited Time Period Discounts - Unlock Now!
      </h3>
      <h3 className="scrolling-text">
        Get Exclusive Limited Time Period Discounts - Unlock Now!
      </h3>
      <h3 className="scrolling-text">
        Get Exclusive Limited Time Period Discounts - Unlock Now!
      </h3>
    </div>
  );
};

export default ScrollText;
