import React from "react";
import Legal from "../components/layout/Legal";

const Disclaimer = () => {
  return (
    <Legal pageTitle="Disclaimer">
      <div className="flex flex-col gap-16">
        <div>
          <p className="b1 text-grey-08 mb-4">General Disclaimer</p>
          <p className="b2 text-grey-05">
            The information provided on EnterInsights.com is for general
            informational purposes only. While we strive to ensure that the
            content on our site is accurate and up-to-date, we make no
            guarantees or representations regarding the completeness,
            reliability, or accuracy of the information. Use of the website and
            participation in any campaigns is at your own risk.
          </p>
        </div>

        <div>
          <p className="b1 text-grey-08 mb-4">No Endorsement</p>
          <p className="b2 text-grey-05">
            The presence of any third-party campaign, link, or advertisement on
            EnterInsights.com does not constitute an endorsement or
            recommendation by us. We are not responsible for the content,
            products, or services offered by third parties.
          </p>
        </div>

        <div>
          <p className="b1 text-grey-08 mb-4">Campaigns</p>
          <p className="b2 text-grey-05">
            All marketing campaigns hosted on EnterInsights.com are created and
            managed by our clients. EnterInsights.com is not responsible for the
            claims or offers made in any campaign. Any interactions with or
            purchases made through campaigns are solely between you and the
            client.
          </p>
        </div>

        <div>
          <p className="b1 text-grey-08 mb-4">Limitation of Liability</p>
          <p className="b2 text-grey-05">
            To the fullest extent permitted by law, EnterInsights.com disclaims
            all liability for any direct, indirect, incidental, or consequential
            damages that may result from the use of our website or participation
            in campaigns. This includes, but is not limited to, loss of data,
            profits, or business opportunities.
          </p>
        </div>

        <div>
          <p className="b1 text-grey-08 mb-4">No Professional Advice</p>
          <p className="b2 text-grey-05">
            The information provided on EnterInsights.com does not constitute
            professional advice. If you require legal, financial, or other
            expert advice, please consult a qualified professional.
          </p>
        </div>

        <div>
          <p className="b1 text-grey-08 mb-4">Changes to Disclaimer</p>
          <p className="b2 text-grey-05">
            We reserve the right to modify this Disclaimer at any time without
            prior notice. Your continued use of the site following any updates
            constitutes acceptance of the revised terms.
          </p>
        </div>
      </div>
    </Legal>
  );
};

export default Disclaimer;
