import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Popup from "./components/common/Popup";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// const popupRoot = ReactDOM.createRoot(document.getElementById('pop'));
// popupRoot.render(
//   <React.StrictMode>
//     <Popup />
//   </React.StrictMode>
// );
