import React from "react";
import dl20 from "../../../assests/HPE/Landing-page/s_dl20.webp";
import dl320 from "../../../assests/HPE/Landing-page/s_dl320.webp";
import dl360 from "../../../assests/HPE/Landing-page/s_dl360.webp";
import dl380 from "../../../assests/HPE/Landing-page/s_dl380.webp";
import ml30 from "../../../assests/HPE/Landing-page/s_ml30.webp";

const data = [
  {
    image: dl20,
    title: "HPE ProLiant DL20 Gen11",
    description:
      "A compact and affordable server designed for small and medium businesses, offering essential performance and security.",
    features: [
      "Compact 1U form factor",
      "Intel Xeon E processors",
      "Cost-effective solution",
      "Easy to deploy and manag",
    ],
  },
  {
    image: ml30,
    title: "HPE ProLiant ML30 Gen11",
    description:
      "A versatile tower server ideal for remote offices and branch offices, providing essential performance and reliability.",
    features: [
      "Tower form factor",
      "Intel Xeon E processors",
      "Quiet operation",
      "Easy to expand and upgrade",
    ],
  },
  {
    image: dl320,
    title: "HPE ProLiant DL320 Gen11",
    description:
      "The perfect balance of performance, versatility, and value. Ideal for a wide range of general-purpose workloads and growing businesses.",
    features: [
      "Dense and compact 1U form factor",
      "Intel Xeon Scalable processors",
      "Flexible configuration options",
      "Enhanced security features",
    ],
  },
  {
    image: dl360,
    title: "HPE ProLiant DL360 Gen11",
    description:
      "The world's most secure industry-standard server, delivering exceptional performance, scalability, and security for diverse workloads.",
    features: [
      "Versatile 1U or 2U form factors",
      "Intel Xeon Scalable processors",
      "High memory and storage capacity",
      "Advanced management capabilities",
    ],
  },
  {
    image: dl380,
    title: "HPE ProLiant DL380 Gen11",
    description:
      "The world's best-selling server, offering unmatched performance, expandability, and reliability for demanding enterprise environments.",
    features: [
      "High-density 2U form factor",
      "Intel Xeon Scalable processors",
      "Massive memory and storage capacity",
      "Advanced I/O capabilities",
    ],
  },
];

const Advantage = () => {
  return (
    <section className="bg-page3-bg bg-cover bg-center bg-no-repeat py-20 md:py-36">
      <div className="container inline-padding">
        <h1 className="text-grey-08 mb-6 md:mb-8 w-full md:w-3/4 xl:w-2/3">
          The HPE ProLiant & Intel Advantage: Experience the Difference
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 lg:gap-6">
          {data.map(({ image, title, description, features }, index) => {
            return (
              <div
                className="col-span-12 md:col-span-6 lg:col-span-4"
                key={index}
              >
                <div className="p-4 bg-white rounded-[1.375rem] border border-solid border-grey-01 h-full">
                  <img src={image} alt="dl20" className="mb-4 rounded-lg" />
                  <p className="b1 text-grey-08 mb-2">{title}</p>
                  <p className="b3 text-grey-04 mb-4">{description}</p>

                  <p className="b2 text-grey-06 mb-2">Key features</p>
                  <ul className="b3 text-grey-04 list-disc pl-4">
                    {features.map((feature, index) => {
                      return <li key={index}>{feature}</li>;
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Advantage;
