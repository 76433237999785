import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import axios from "../config";
import Pagination from "../components/common/Pagination";
import { useNavigate } from "react-router-dom";
import Button from "../components/common/Button";
import { Download } from "lucide-react";
import { saveAs } from "file-saver";
import Spinner from "../components/common/Spinner";

const questions = [
  "Would you be interested in a server solution which will help you to accelerate AI outcomes with a trusted edge to cloud security architecture?",
  "Are you a decision maker or influencer when it comes to Device purchases and Technology investment at your organization?",
  "Do you have a budget in mind for the above?",
  "In what timeframe would you like to invest in such a solution?",
];

const LeadsPage = () => {
  const [leadsData, setLeadsData] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(leadsData.length === 0);
  const token = localStorage.getItem("token");

  useEffect(() => {
    setIsLoading(true);
    axios
      .get("/get-leads", {
        headers: {
          Authorization: `Bearer ${token ?? ""}`,
        },
      })
      .then((response) => {
        setLeadsData(response.data.reverse());
      })
      .catch((error) => {
        // console.error("Error fetching leads data:", error);
        if (error.status === 403 || error.status === 401) {
          localStorage.removeItem("token");
          navigate("/auth");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);

  const leadsPerPage = 10;
  const totalPages = Math.ceil(leadsData.length / leadsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // console.log(leadsData);

  const formattedLeadsData = leadsData.map((lead) => {
    const date = new Date(lead.timestamp);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    const formattedDate = date.toLocaleDateString(undefined, options);

    return {
      ...lead,
      timestamp: formattedDate,
    };
  });

  const handleDownloadCSV = () => {
    const csvData = formattedLeadsData.map((lead, index) => {
      const lastName = lead.last_name ? lead.last_name : "-";
      const utm_source = lead.utm_source ? lead.utm_source : "-";
      const utm_medium = lead.utm_medium ? lead.utm_medium : "-";
      const utm_campaign = lead.utm_campaign ? lead.utm_campaign : "-";

      return `${lead.first_name},${lastName},${lead.email},"${lead.address}",${lead.phone},${lead.country},${lead.company},${lead.designation},${utm_source},${utm_medium},${utm_campaign},${lead.interested_in},${lead.device_purchases},${lead.budget},${lead.time_frame},"${lead.timestamp}"`;
    });

    const csvContent = [
      `First Name,Last Name,Email,Address,Phone Number,Country,Company,Designation,UTM_SOURCE,UTM_MEDIUM,UTM_CAMPAIGN,${questions.join(
        ","
      )},Time Stamp,`,
    ]
      .concat(csvData)
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    saveAs(blob, `hpe_leads${new Date().getTime()}.csv`);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/auth");
  };

  return (
    <Layout title="Leads">
      <div className="leads-page min-h-screen">
        <div className="leads-masthead-container bg-blue-06">
          <div className="container">
            <div className="d-flex justify-content-between">
              <h1 className="text-white"> Leads </h1>
            </div>
          </div>
        </div>
        <div className="container mx-auto">
          {!isLoading ? (
            leadsData.length ? (
              <>
                <div className="my-6 flex justify-end gap-4">
                  <div>
                    <Button
                      text="Download CSV"
                      Icon={Download}
                      onClick={handleDownloadCSV}
                    />
                  </div>
                  <div>
                    <Button text="Logout" onClick={handleLogout} />
                  </div>
                </div>
                <div className="leads-data-container mb-4">
                  <div className="leads-data-head">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col">
                        <p className="b3 text-grey-05 p-2">Name and Email</p>
                      </div>
                      <div className="col">
                        <p className="b3 text-grey-05 p-2">Address</p>
                      </div>
                      <div className="col">
                        <p className="b3 text-grey-05 p-2">Country</p>
                      </div>
                      <div className="col">
                        <p className="b3 text-grey-05 p-2">
                          Company name and Designation
                        </p>
                      </div>
                      <div className="col">
                        <p className="b3 text-grey-05 p-2">Phone number</p>
                      </div>
                      <div className="col">
                        <p className="b3 text-grey-05 p-2">Time stamp</p>
                      </div>
                    </div>
                  </div>
                  <div className="border border-b-grey-04 mb-4"></div>
                  <div className="leads-data-body">
                    {formattedLeadsData
                      .slice(
                        (currentPage - 1) * leadsPerPage,
                        currentPage * leadsPerPage
                      )
                      .map((lead) => {
                        return (
                          <div
                            key={lead.id}
                            className="leads-data border-b border-b-grey-04 py-4"
                          >
                            <div className="grid grid-cols-6 gap-6 mb-6">
                              <div className="col">
                                <p className="b3 text-grey-08 p-2 b4">
                                  <span className="capitalize">
                                    {lead.first_name} {lead.last_name}
                                  </span>
                                  <br />
                                  <span className="text-grey-04">
                                    {lead.email}
                                  </span>
                                </p>
                              </div>
                              <div className="col">
                                <p className="b3 text-grey-08 p-2 b4">
                                  {lead.address}
                                </p>
                              </div>
                              <div className="col">
                                <p className="b3 text-grey-08 p-2 b4">
                                  {lead.country}
                                </p>
                              </div>
                              <div className="col">
                                <p className="b3 text-grey-08 p-2 b4 capitalize">
                                  <span>{lead.company}</span>
                                  <br />
                                  <span className="text-grey-04">
                                    {lead.designation}
                                  </span>
                                </p>
                              </div>
                              <div className="col">
                                <p className="b3 text-grey-08 p-2 b4">
                                  {lead.phone}
                                </p>
                              </div>
                              <div className="col">
                                <p className="b3 text-grey-08 p-2 b4">
                                  {lead.timestamp}
                                </p>
                              </div>
                            </div>
                            <div className="flex flex-col gap-4">
                              <div className="flex items-center justify-between text-grey-08">
                                <p className="b4 max-w-[60%]">{questions[0]}</p>
                                <p className="b4">{lead.interested_in}</p>
                              </div>
                              <div className="flex items-center justify-between text-grey-08">
                                <p className="b4 max-w-[60%]">{questions[1]}</p>
                                <p className="b4">{lead.device_purchases}</p>
                              </div>
                              <div className="flex items-center justify-between text-grey-08">
                                <p className="b4 max-w-[60%]">{questions[2]}</p>
                                <p className="b4">{lead.budget}</p>
                              </div>
                              <div className="flex items-center justify-between text-grey-08">
                                <p className="b4 max-w-[60%]">{questions[3]}</p>
                                <p className="b4">{lead.time_frame}</p>
                              </div>
                              <div className="flex items-center justify-between text-grey-08">
                                <p className="b4 max-w-[60%]">utm_source</p>
                                <p className="b4">
                                  {lead.utm_source ? lead.utm_source : "-"}
                                </p>
                              </div>
                              <div className="flex items-center justify-between text-grey-08">
                                <p className="b4 max-w-[60%]">utm_medium</p>
                                <p className="b4">
                                  {lead.utm_medium ? lead.utm_medium : "-"}
                                </p>
                              </div>
                              <div className="flex items-center justify-between text-grey-08">
                                <p className="b4 max-w-[60%]">utm_campaign</p>
                                <p className="b4">
                                  {lead.utm_campaign ? lead.utm_campaign : "-"}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="pagination-container mb-8">
                  <Pagination
                    pages={totalPages}
                    handlePageChange={handlePageChange}
                    activePage={totalPages ? currentPage - 1 : -1}
                  />
                </div>
              </>
            ) : (
              <p className="b2 text-grey-08 py-10">No Leads</p>
            )
          ) : (
            <div className="flex justify-center py-10">
              <div className="flex items-center gap-4">
                <Spinner styles="text-blue-05" size={60} />
                <p className="b2 text-blue-05">Fetching Leads...</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default LeadsPage;
