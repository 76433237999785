import React from "react";

const IconCard = ({ image, title, description }) => {
  return (
    <div className="py-4 px-6 lg:py-8 lg:px-10 rounded-2xl bg-white h-full">
      <img src={image} alt={`${title}-image`} className="mb-4" />
      <p className="b1 text-grey-08 mb-1">{title}</p>
      <p className="b3 text-grey-05">{description}</p>
    </div>
  );
};

export default IconCard;
