import { Info } from "lucide-react";
import React, { forwardRef } from "react";
import TextError from "./TextError";

const Input = forwardRef(
  ({ label, styles = "", prefixText = "", error, ...rest }, ref) => {
    const { type } = rest;

    return (
      <div className="flex flex-col gap-1">
        <label className="b4 text-blue-04">{label}</label>
        <div className="relative">
          <input
            ref={ref}
            className={`w-full py-3 input-field text-grey-04 bg-grey-01 placeholder-lg md:placeholder-md sm:placeholder-sm outline-none border border-transparent rounded hover:border-[1.5px] hover:border-blue-06 hover:bg-white focus:text-blue-06 focus:border-blue-06 focus:bg-white ${styles} ${
              type === "tel" ? "ps-20 pe-4" : "px-4"
            }`}
            {...rest}
          />
          {type === "tel" && (
            <div className="absolute left-0 top-1/2 translate-y-[-50%] w-16 h-2/3 pe-2 border-r border-r-grey-04 flex items-center justify-end">
              <p className="input-prefix input-field text-grey-04">
                {prefixText}
              </p>
            </div>
          )}
        </div>
        {error && <TextError error={error} />}
      </div>
    );
  }
);

export default Input;
