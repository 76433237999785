import React from "react";
import Masthead from "../components/sections/homepage/Masthead";
import Empower from "../components/sections/homepage/Empower";
import Advantage from "../components/sections/homepage/Advantage";
import Features from "../components/sections/homepage/Features";
import Solution from "../components/sections/homepage/Solution";
import Layout from "../components/layout/Layout";
import CarouselPage from "./CarouselPage";

export const Homepage = () => {
  return (
    <Layout title="HPE ProLiant Gen11">
      <Masthead />
      <CarouselPage />
      <Empower />
      <Advantage />
      <Features />
      <Solution />
    </Layout>
  );
};
