import { useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";

const Layout = ({ children, title = "React App" }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
  return (
    <main className="relative">
      <Header />
      {children}
      <Footer />
    </main>
  );
};

export default Layout;
